.site-header {
    text-align: center;
	margin: 0 auto;
	padding: 80px 0 0;
	max-width: 820px;
}
.site-header__title {
    text-align: center;
	margin: 0;
	font-family: Montserrat, sans-serif;
	font-size: 2.5rem;
	font-weight: 700;
	line-height: 1.1;
	text-transform: uppercase;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}


.main-content {
    text-align: center;
	margin: 0 auto;
	max-width: 820px;
}
.main-content__checkmark {
	font-size: 4.0625rem;
	line-height: 1;
	color: #24b663;
}
.main-content__body {
	margin: 20px 0 0;
	font-size: 1rem;
	line-height: 1.4;
}

.site-footer {
    text-align: center;
	margin: 0 auto;
    //padding-top: 80px;
	padding: 80px 0 25px;
	//padding: 0;
	max-width: 820px;
}
.site-footer__fineprint {
	font-size: 0.9375rem;
	line-height: 1.3;
	font-weight: 300;
}