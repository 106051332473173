@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

.formadd-main
{
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 30px);
    left: 0;
    top: 0;
    margin: 10px;
    //box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
    background: white;
    border-radius: 15px;
    

    .formadd-header-group
    {
        display: flex;
        align-items: center;  
        justify-content: space-between;    
        background-color: #235789;
            
        .formadd-group-buttons
        {
            display: flex;
            .formadd-buttons
            {
                position: relative;
                color:white;
                justify-content: flex-end;
                padding: 2px;
                display: flex;
                margin-left: 10px;
            }
        }  
        .formadd-header
        {
           
            position: relative;
            width: 100%;
            padding-left: 20px;
            height: 52px;
            left: 0;
            top: 0;
            
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 41px;
            display: flex;
            align-items: center;
            
            color: white;        
        }              
    }


    .formadd-input
    {
        padding: 10px;
        border: 1px solid #979797;
        height: 30px;
        background: white;
        border-radius: 8px;    
        margin: 10px;    
    }

    .formadd-type
    {
        height: 30px;
        background: white;
        border: 1px solid #979797;
        border-radius: 8px;    
        margin: 10px;    
    }    

    .formadd-description
    {
        padding: 5px;
        height: 30px;
        width: 400px;
        border: 1px solid #979797;
        background: white;
        border-radius: 8px;    
        margin: 10px;    
    }

    .formadd-info
    {
        position: relative;
        color:white;
        justify-content: flex-start;
        padding: 2px;
        display: flex;
        margin-left: 20px;
    }


    
    .formadd-name
    {
        width: 249px;
        height: 30px;
        left: 1325px;
        top: 110px;
        
        background: #FFFFFF;
        border: 1px solid #979797;
        box-sizing: border-box;
        border-radius: 8px;        
    }
    .formadd-editor
    {
        position: relative;
        border: 1px solid #979797;
        margin-left: 20px;
        margin-right: 20px;
        height: calc(100% - 150px);
        padding: 10px;
        overflow-y: scroll;
    }

    .formadd-span {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 25px;   
        color:black;  
    }

    .span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;   
        color:white;      
    }

    .formadd-add
    {
        width: 120px;
        height: 35px;
        top: 229px;
        margin-right: 10px;

        border: none; /* Remove borders */
        color: white; /* White text */
        padding: 2px; /* Some padding */
        cursor: pointer; /* Mouse pointer on hover */

        background: #1074C1;
        border-radius: 23px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        align-items: center;
    }   

    .formadd-reset
    {
        width: 180px;
        height: 35px;
        top: 229px;
        border: none; /* Remove borders */
        color: white; /* White text */
        padding: 2px; /* Some padding */
        cursor: pointer; /* Mouse pointer on hover */

        background: #1074C1;
        border-radius: 23px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        align-items: center;
    }     
    
    .formadd-back
    {
        width: 120px;
        height: 35px;
        top: 229px;
       // margin-right: 20px;

        border: none; /* Remove borders */
        color: white; /* White text */
        padding: 2px; /* Some padding */
        cursor: pointer; /* Mouse pointer on hover */

        background: #1074C1;
        border-radius: 23px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        align-items: center;
    }       

}

.formadd-preview
{
    display: flex;
    position: relative;
    justify-content: center;    
    background: white;
    padding-top: 10px;
    .formadd-content
    {
       // display: flex;
       // height: 100vh;
        width: calc(100vw - 120px);
    }   
}


@keyframes spinner {
    to {transform: rotate(360deg);}
  }
  
  .spinner-eclipse {
    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 41.5%;
      width: 100px;
      height: 100px;
      margin-top: -15px;
      margin-left: -15px;
      border-radius: 50%;
      border: 3px solid theme-color('white');
      border-top-color: theme-color('primary');
      animation: spinner .6s linear infinite;
  
    }
  }
  
  .spinner-eclipse-centered:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 30px;
    height: 30px;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    border-radius: 50%;
    border: 1px solid #ccc;
    border-top-color: theme-color('primary');
    animation: spinner .6s linear infinite;
  }
  

